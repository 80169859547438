import { MicroCMSAuthType } from "@/types/MicroCMSConfig.types";
import { getMicroCMSConnection } from "@/utils/getMicroCMSConnection";
import { useEffect, useState } from "react";

const useFetchShopDetail = (
  shopId: string,
  endpointId: string,
  microCMSAuth: MicroCMSAuthType
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<any>();
  const [hasError, setHasError] = useState(false);
  const errorMessage = "投稿が取得できません。";

  useEffect(() => { 
    const client = getMicroCMSConnection(microCMSAuth.serviceDomain, microCMSAuth.apiKey);

    const getContent = async() => {
      try {
        const apiResponse = await client.get({
          endpoint: endpointId,
          contentId: shopId
        });
        setResponse(apiResponse);
      } catch (e) {
        console.log(e);
        setHasError(true)
      } finally {
        setIsLoading(false);
      }
    }
    getContent()
  },[shopId])

  return {isLoading, response, hasError, errorMessage}
  
}


export default useFetchShopDetail;