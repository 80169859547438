import styles from "./ShopSectionWrapper.module.scss";
import { ShopSectionWrapperType } from "./ShopSectionWrapper.types";

const ShopSectionWrapper = ({ id, children}: ShopSectionWrapperType) => {
  return (
    <section id={id} className={styles['shop-sec']}>
      <div className={styles['shop-sec__inner']}>
        {children}
      </div>
    </section>
  )
}

export default ShopSectionWrapper;