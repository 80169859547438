'use client';

import LayoutSection from "@/components/layouts/LayoutSection/LayoutSection";
import useFetchContentsForShopPage from "@/hooks/useFetchContentsForShopPage.hooks";
import parse from "html-react-parser";
import { Suspense } from "react";
import { Endpoints } from "../../_configs/Endpoints";
import { MicroCMSQueryForShopPageType } from "../QueryForShopPage.types";
import VoiceArchiveBox from "../VoiceArchiveBox/VoiceArchiveBox";

const BoxVoiceForShop = (props: MicroCMSQueryForShopPageType) => {
  const {microCMSAuth, endpointName, limit} = props;
  const slug = Endpoints[endpointName].archivePath;

  const {isLoading, response, hasError, errorMessage} = useFetchContentsForShopPage(microCMSAuth, endpointName, limit);

  if (isLoading) {
    return (
      <LayoutSection>
        <></>
      </LayoutSection>
    );
  };

  if (hasError) {
    return (
      <LayoutSection>
        <p style={{textAlign: "center"}}>{parse(errorMessage)}</p>
      </LayoutSection>
    );
  }

  return (
    <Suspense>
      {response ? (
        <VoiceArchiveBox
        articleItems={response.contents}
        slug={slug}
      />
      ) : null} 
      
    </Suspense>
  )
}

export default BoxVoiceForShop;