import React from 'react';
import styles from "./DynamicTitle.module.scss";
import { DyanmicTitleType } from "./DynamicTitle.types";

const DynamicTitle = (props: DyanmicTitleType) => {
  const {tag = 'div', text, textSub,id} = props;
  let className = styles[tag] || '';
  const titleText = React.createElement(tag, {className:className,id:id}, text);
  if(textSub) {
    className = styles[tag + "__sub"] || '';
    const titleSubText = React.createElement("div", {className}, textSub);
    className = styles[tag + "__wrapper"] || '';
    return React.createElement("div", {className}, titleSubText,titleText);
  } else {
    className = styles[tag + "__wrapper"] || '';
    return React.createElement("div", {className}, titleText);
  }
}

export default DynamicTitle;