type ScheduleTableProps = {
  shinryo: {
    fieldId: string;
    openAM: string;
    openAMBikou: string;
    openPM: string;
    openPMBikou: string;
  },
  table: {
    fieldId: string,
    mondayAM: string[];
    mondayAMBikou: string;
    mondayPM: string[];
    mondayPMBikou: string;
    tuesdayAM: string[];
    tuesdayAMBikou: string;
    tuesdayPM: string[];
    tuesdayPMBikou: string;
    wednesdayAM: string[];
    wednesdayAMBikou: string;
    wednesdayPM: string[];
    wednesdayPMBikou: string;
    thursdayAM: string[];
    thursdayAMBikou: string;
    thursdayPM: string[];
    thursdayPMBikou: string;
    fridayAM: string[];
    fridayAMBikou: string;
    fridayPM: string[];
    fridayPMBikou: string;
    saturdayAM: string[];
    saturdayAMBikou: string;
    saturdayPM: string[];
    saturdayPMBikou: string;
    sundayAM: string[];
    sundayAMBikou: string;
    sundayPM: string[];
    sundayPMBikou: string;
    holidayAM: string[];
    holidayAMBikou: string;
    holidayPM: string[];
    holidayPMBikou: string;
  }
}

const ScheduleTable = (props: ScheduleTableProps) => {
  const { shinryo, table } = props;
  return (
    <table className="shop-open-table">
      <thead>
        <tr>
          <th>受付時間</th>
          <th>月</th>
          <th>火</th>
          <th>水</th>
          <th>木</th>
          <th>金</th>
          <th>土</th>
          <th>日</th>
          <th>祝</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th className="shop-open-table__index">
            <div className="shop-open-table__index-time">午前</div>
            <div>{shinryo?.openAM}</div>
          </th>
          <td>
            {table?.mondayAM?.join(', ')}
            {table?.mondayAMBikou && <span className="shop-open-table__note">{table.mondayAMBikou}</span>}
          </td>
          <td>
            {table?.tuesdayAM?.join(', ')}
            {table?.tuesdayAMBikou && <span className="shop-open-table__note">{table.tuesdayAMBikou}</span>}
          </td>
          <td>
            {table?.wednesdayAM?.join(', ')}
            {table?.wednesdayAMBikou && <span className="shop-open-table__note">{table.wednesdayAMBikou}</span>}
          </td>
          <td>
            {table?.thursdayAM?.join(', ')}
            {table?.thursdayAMBikou && <span className="shop-open-table__note">{table.thursdayAMBikou}</span>}
          </td>
          <td>
            {table?.fridayAM?.join(', ')}
            {table?.fridayAMBikou && <span className="shop-open-table__note">{table.fridayAMBikou}</span>}
          </td>
          <td>
            {table?.saturdayAM?.join(', ')}
            {table?.saturdayAMBikou && <span className="shop-open-table__note">{table.saturdayAMBikou}</span>}
          </td>
          <td>
            {table?.sundayAM?.join(', ')}
            {table?.sundayAMBikou && <span className="shop-open-table__note">{table.sundayAMBikou}</span>}
          </td>
          <td>
            {table?.holidayAM?.join(', ')}
            {table?.holidayAMBikou && <span className="shop-open-table__note">{table.holidayAMBikou}</span>}
          </td>
        </tr>
        <tr>
          <th className="shop-open-table__index">
            <div className="shop-open-table__index-time">午後</div>
            <div>
              {shinryo?.openPM}<br />
              {shinryo?.openPMBikou && <span className="shop-open-table__note">{shinryo.openPMBikou}</span>}
            </div>
          </th>
          <td>
            {table?.mondayPM?.join(', ')}
            {table?.mondayPMBikou && <span className="shop-open-table__note">{table.mondayPMBikou}</span>}
          </td>
          <td>
            {table?.tuesdayPM?.join(', ')}
            {table?.tuesdayPMBikou && <span className="shop-open-table__note">{table.tuesdayPMBikou}</span>}
          </td>
          <td>
            {table?.wednesdayPM?.join(', ')}
            {table?.wednesdayPMBikou && <span className="shop-open-table__note">{table.wednesdayPMBikou}</span>}
          </td>
          <td>
            {table?.thursdayPM?.join(', ')}
            {table?.thursdayPMBikou && <span className="shop-open-table__note">{table.thursdayPMBikou}</span>}
          </td>
          <td>
            {table?.fridayPM?.join(', ')}
            {table?.fridayPMBikou && <span className="shop-open-table__note">{table.fridayPMBikou}</span>}
          </td>
          <td>
            {table?.saturdayPM?.join(', ')}
            {table?.saturdayPMBikou && <span className="shop-open-table__note">{table.saturdayPMBikou}</span>}
          </td>
          <td>
            {table?.sundayPM?.join(', ')}
            {table?.sundayPMBikou && <span className="shop-open-table__note">{table.sundayPMBikou}</span>}
          </td>
          <td>
            {table?.holidayPM?.join(', ')}
            {table?.holidayPMBikou && <span className="shop-open-table__note">{table.holidayPMBikou}</span>}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ScheduleTable;
