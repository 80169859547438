import styles from './LinkButton.module.scss';
import { LinkButtonType } from "./LinkButton.types";

const LinkButton = (props: LinkButtonType) => {
  const {linkTo, linkText, isExternal,className} = props;
  return (
    <div className={`${styles.link_button} ${className ? className : ''}`}>
      {isExternal ? (
        <a href={linkTo} target="_blank" rel="noopener noreferer">
          {linkText}
        </a>
      ):(
        <a href={linkTo}>
          {linkText}
        </a>
      )}
    </div>
  )
}

export default LinkButton;